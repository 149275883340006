@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background: #fce6c5;
  overflow: hidden;
}

main > div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-flow: column;
}

.wrapper {
  background: #fff;
  border-radius: 15px;
  padding: 25px;
  max-width: 380px;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.wrapper header {
  font-size: 22px;
  font-weight: 600;
}
.wrapper .poll-area {
  margin: 20px 0 15px 0;
}
.poll-area label {
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 8px 15px;
  border: 2px solid #e6e6e6;
  transition: all 0.2s ease;
}
.poll-area label:hover {
  border-color: #ddd;
}
label.selected {
  border-color: #e79822;
}
label .row {
  display: flex;
  pointer-events: none;
  justify-content: space-between;
}
label .row .column {
  display: flex;
  align-items: center;
}
label .row .circle {
  height: 19px;
  width: 19px;
  display: block;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}
label.selected .row .circle {
  border-color: #e79822;
}
label .row .circle::after {
  content: "";
  height: 11px;
  width: 11px;
  background: #e79822;
  border-radius: inherit;
  position: absolute;
  left: 2px;
  top: 2px;
  display: none;
}
.poll-area label:hover .row .circle::after {
  display: block;
  background: #e6e6e6;
}
label.selected .row .circle::after {
  display: block;
  background: #e79822 !important;
}
label .row span {
  font-size: 16px;
  font-weight: 500;
}
label .row .percent {
}
label .progress {
  height: 7px;
  width: 100%;
  position: relative;
  background: #f0f0f0;
  margin: 8px 0 3px 0;
  border-radius: 30px;
  pointer-events: none;
}
label .progress:after {
  position: absolute;
  content: "";
  height: 100%;
  background: #ccc;
  width: calc(1% * var(--w));
  border-radius: inherit;
  transition: all 0.2s ease;
}
label.selected .progress::after {
  background: #e79822;
}
label.selectall .progress,
label.selectall .row .percent {
  display: block;
}
input[type="radio"],
input[type="checkbox"] {
  display: none;
}

.status {
  position: fixed;
  bottom: 10px;
  left: 10px;
}
.status.offline .online-msg {
  left: 0;
}
.status.offline .offline-msg {
  display: block;
}
.status div {
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 1rem;
  color: white;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
}
.status .online-msg {
  background: green;
  display: block;
}
.status .offline-msg {
  background: red;
}

/**
 * Base Timer
 */
.base-timer {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

@media (max-width: 1024px) {
  .base-timer {
    width: 100px;
    height: 100px;
  }

  .base-timer__label {
    width: 100px;
    height: 100px;
    font-size: 24px;
  }
}

/* CSS */
.button-77 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

@media (min-width: 768px) {
  .button-77 {
    padding: 19px 32px;
  }
}

.button-77:before,
.button-77:after {
  border-radius: 80px;
}

.button-77:before {
  background-color: rgba(249, 58, 19, 0.32);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-77:after {
  background-color: initial;
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-77:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
}

.button-77:active:not(:disabled) {
  color: #ccc;
}

.button-77:active:not(:disabled):after {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-77:disabled {
  cursor: default;
  opacity: 0.24;
}

.buttons-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.good {
  background-color: #91ee91b2;
}

label.good .row .circle,
label.good .row .circle::after {
  border-color: #196119 !important;
}

.good .progress {
  background-color: #9bca9b;
}

label.good.selected .progress::after {
  background-color: #196119;
}

label.good.selected {
  border-color: #196119;
}

label.good.selected .row .circle,
label.good.selected .row .circle::after {
  border-color: #196119 !important;
  background-color: #196119 !important;
}
